import React from 'react'
import Image from 'next/image'
import { breakpoint, mixin } from 'src/styles'
import { cloudflareLoader } from 'src/utils/image'
import { Flex } from 'src/components/Display/Flex'
import content from './content'

interface ResearchStudySectionProps {
  image: string
}

export const ResearchStudySection = ({ image }: ResearchStudySectionProps) => {
  return (
    <Styles.Container>
      <Styles.TextColumn largeAndUp={{ width: '50%' }}>
        <Styles.Title>{content.title}</Styles.Title>
        <Styles.Blurb>{content.blurb}</Styles.Blurb>
      </Styles.TextColumn>
      <Styles.ImageColumn largeAndUp={{ width: '449px' }}>
        <Image
          alt='research'
          height={599}
          src={image}
          width={474}
          loader={cloudflareLoader}
          unoptimized
        />
      </Styles.ImageColumn>
    </Styles.Container>
  )
}

const Styles = {
  Container: styled.div`
    ${mixin.flexCenterVertical}
    ${mixin.flexSpaceAround}
    padding: 0 110px;
    column-gap: 100px;

    ${breakpoint.mediumAndDown} {
      align-items: normal;
      flex-direction: column;
      row-gap: 22px;
      padding: 0 20px;
    }
  `,
  TextColumn: styled(Flex.Cell)`
    align-items: flex-start;
    text-align: left;
    justify-content: center;
    margin: 0.9rem 0;
    padding: 0px 0px 5px 0px;

    ${breakpoint.mediumAndDown} {
      align-items: center;
      text-align: center;
      flex-direction: column;
      row-gap: 40px;
      padding: 0 20px;
    }
  `,
  Title: styled.div`
    font-size: 36px;
    font-weight: 500;
    line-height: 44px;
    letter-spacing: 0.3px;
    color: black;

    ${breakpoint.mediumAndDown} {
      font-size: 20px;
      line-height: 24px;
    }
  `,
  Blurb: styled.div`
    font-size: 16px;
    font-weight: 300;
    font-style: normal;
    line-height: 24px;
    letter-spacing: 0.3px;
    color: black;
    margin-top: 22px;
  `,
  ImageColumn: styled(Flex.Cell)`
    display: flex;
    flex-direction: column;

    ${breakpoint.mediumAndDown} {
      align-items: center;
      justify-content: center;
    }
  `,
}
