import { breakpoint } from 'src/styles'

export const LandingStyles = {
  Title: styled.h2`
    color: #333333;
    font-size: 44px;
    font-weight: 250;
    letter-spacing: 0.005em;
    line-height: 52px;
    margin-bottom: 0;

    ${breakpoint.mediumAndDown} {
      font-size: 24px;
      font-weight: 300;
      letter-spacing: 0.3px;
      line-height: 24px;
    }
  `,
  Blurb: styled.div`
    font-size: 16px;
    font-weight: 300;
    letter-spacing: 0.005em;
    line-height: 24px;

    b {
      font-weight: 500;
    }

    ${breakpoint.mediumAndDown} {
      font-size: 16px;
      line-height: 24px;
    }
  `,
  YellowShadow: styled.span`
    ${({ text }) => css`
      display: inline-block;
      position: relative;
      z-index: 1;

      ::before {
        content: '${text}';
        color: var(--yellow);
        font-weight: 600;
        left: 2px;
        letter-spacing: -0.05em;
        position: absolute;
        z-index: -1;
      }
    `}
  `,
}
