import { variants } from 'src/providers/Split/utils'

const content = {
  [variants.marketingPageCopy.variantA]: {
    title: 'The one-stop platform for women',
    blurb: `A class guide for your cycle, 10-minute Express workouts for busy days, prenatal +
      postnatal classes, wellness practices, and more. obé has everything you need to stay
      in balance.`,
  },
  [variants.marketingPageCopy.variantB]: {
    title: 'The one-stop platform for everything',
    blurb: `Strength training for healthy bones? Check. 11 different ways to boost cardio? Yes,
      please. Wellness practices for a happy mind and classes for women’s cycles? Count on
      obé for all of it.`,
  },
  control: {
    title: 'Stay positively charged',
    blurb: `Hello, accountability. obé makes it easy to plan your week of workouts, track your
      progress, and celebrate every milestone.`,
  },
}

export default content
