import { getContentCollectionBySlug } from 'src/models/collection'
import { getObeCarouselBySlug } from 'src/models/carousel'
import { getPlanData, processPlanData } from 'src/models/plan'
import { getInstructors } from 'src/models/instructor'
import GuestView from 'src/views/Home/Guest'
import { MarketingLayout } from 'src/components/App/Layouts/MarketingLayout'
import { NextSeo } from 'next-seo'
import { processAssetCollection } from 'src/utils/processAssetCollection'
import { RedirectIf } from 'src/components/App/RedirectIf'
import { SplitAnonymousTreatmentsProvider } from 'src/providers/Split/AnonymousTreatmentsProvider'

const Landing = ({
  assetCollection,
  marketing2023RedesignCollection,
  instructors,
  planData,
  selfieReel,
  articlesBlock,
  pressTestimonials,
}) => {
  const assets = processAssetCollection(assetCollection)
  const marketing2023RedesignAssets = processAssetCollection(marketing2023RedesignCollection)
  const plans = processPlanData(planData)

  return (
    <RedirectIf.IsMember>
      <NextSeo
        title='Workouts On-Demand - Live Fitness Classes | obé Fitness'
        canonical='https://obefitness.com'
        openGraph={{
          title: 'Workouts On-Demand - Live Fitness Classes | obé Fitness',
        }}
      />
      <SplitAnonymousTreatmentsProvider context='homepageLoggedOut'>
        <MarketingLayout>
          <GuestView
            articlesBlock={articlesBlock}
            pressTestimonials={pressTestimonials}
            marketing2023RedesignAssets={marketing2023RedesignAssets}
            assets={assets}
            instructors={instructors}
            plans={plans}
            selfieReel={selfieReel}
          />
        </MarketingLayout>
      </SplitAnonymousTreatmentsProvider>
    </RedirectIf.IsMember>
  )
}

export const getStaticProps = async () => {
  const assetCollection = await getContentCollectionBySlug('marketing-home-page')
  const marketing2023RedesignCollection = await getContentCollectionBySlug(
    'marketing-redesign-2023-web'
  )
  const instructors = await getInstructors()
  const { data: plans } = await getPlanData()
  const selfieReel = await getObeCarouselBySlug('marketing-page-selfies')
  const articlesBlock = await getObeCarouselBySlug('b5-articles-block')
  const pressTestimonials = await getObeCarouselBySlug('b8-press-testimonials')

  return {
    props: {
      assetCollection,
      marketing2023RedesignCollection,
      instructors,
      planData: plans,
      selfieReel,
      articlesBlock,
      pressTestimonials,
    },
    revalidate: parseInt(process.env.DEFAULT_REVALIDATE_SECONDS),
  }
}

export default Landing
