import { variants } from 'src/providers/Split/utils'

const content = {
  [variants.marketingPageCopy.variantA]: {
    title: 'Personalized fitness made for women',
    blurb: 'Work out smarter, not harder. Start looking forward to moving your body with obé.',
  },
  [variants.marketingPageCopy.variantB]: {
    title: 'Personalized fitness to supercharge your health',
    blurb: `Working out is the best tool for living your longest, healthiest life. obé makes it
      easy.`,
  },
  control: {
    title: 'Movement changes everything',
    blurb: 'Join us for accountability, empowerment, and results—all in one platform.',
  },
}

export default content
