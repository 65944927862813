import React from 'react'
import { breakpoint } from 'src/styles'
import ctaBackground from 'public/quiz/quiz-preview-cta-background.png'
import { useRouter } from 'src/hooks/useRouter'

interface QuizPreviewCTAProps {
  linkTo: string
}
const QuizPreviewCTA = ({ linkTo }: QuizPreviewCTAProps) => {
  const { push } = useRouter()

  const handleCTAClick = () => {
    push(linkTo)
    obe.analytics.track(obe.events.continue_to_quiz, {})
  }

  return (
    <Styles.Wrapper>
      <Styles.Container backgroundImage={ctaBackground.src}>
        <Styles.FirstLine>If your workouts aren’t working, it’s not your fault</Styles.FirstLine>
        <Styles.SecondLine>
          Get personalized, progressive fitness plan that saves time, delivers results, and adapts
          to how you form habits.
        </Styles.SecondLine>
        <Styles.Button onClick={handleCTAClick}>
          <Styles.ButtonText>Take the quiz</Styles.ButtonText>
        </Styles.Button>
      </Styles.Container>
    </Styles.Wrapper>
  )
}

const Styles = {
  Wrapper: styled.div`
    flex: 1;
    background-color: #f8f8f8;
    padding: 48px 0;
  `,
  Container: styled.div<{ backgroundImage: string }>`
    flex: 1;
    height: 300;
    text-align: center;
    padding: 46px;
    margin-bottom: 48px;
    margin-left: 120px;
    margin-right: 120px;
    border-radius: 56px;

    ${breakpoint.mediumAndDown} {
      padding: 20px;
      padding-bottom: 50px;
      margin-bottom: 48px;
      margin-left: 100px;
      margin-right: 100px;
    }

    ${breakpoint.smallAndDown} {
      padding: 20px;
      padding-bottom: 50px;
      margin-bottom: 48px;
      margin-left: 50px;
      margin-right: 50px;
    }

    ${breakpoint.xsmallAndDown} {
      padding: 20px;
      padding-bottom: 50px;
      margin-bottom: 48px;
      margin-left: 10px;
      margin-right: 10px;
    }

    ${({ backgroundImage }) => css`
      background-image: url(${backgroundImage});
      background-repeat: no-repeat;
      background-size: cover;
    `}
  `,
  FirstLine: styled.p`
    color: var(--neutrals800);
    font-family: 'playfair display';
    font-size: 32px;
    font-weight: 800;
    line-height: 44px;
    letter-spacing: 0.3px;
    margin-bottom: 0;

    ${breakpoint.smallAndDown} {
      font-size: 25px;
    }
  `,
  SecondLine: styled.p`
    font-family: centra;
    max-width: 586px;
    margin: 0 auto;
    font-size: 20px;
    font-weight: 300;
    line-height: 28px;
    letter-spacing: 0.5px;
    color: var(--neutrals800);
    margin-bottom: 48px;
    margin-top: 10px;

    ${breakpoint.smallAndDown} {
      font-size: 17px;
    }
  `,
  Button: styled.button`
    padding: 26px 26px;
    text-align: center;
    padding: 16px 32px;
    background-color: var(--neutrals100);
    border: 2px solid var(--obe-color-neutrals-800, #000);
    border-radius: 40px;
    :focus {
      outline: 0;
    }
  `,
  ButtonText: styled.p`
    display: inline;
    font-size: 18px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.3px;
  `,
}

export { QuizPreviewCTA }
