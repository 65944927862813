import React from 'react'
import { breakpoint, breakPointMins } from 'src/styles'
import { DirectusFile } from 'src/types/DirectusFile'
import { SwiperCarousel } from 'src/components/Carousel/Swiper'
import { Icon } from 'src/components/Icon'
import { ArticleCard } from './ArticleCard'
import content from './content'

interface ArticlesBlockSectionProps {
  assets: DirectusFile[] | undefined
}

const carouselSettings = {
  slidesPerView: 1,
  spaceBetween: 32,
  breakpoints: {
    [breakPointMins.medium]: {
      slidesPerView: 3,
      spaceBetween: 64,
    },
  },
  navigation: {
    nextEl: '.nextArticle',
    prevEl: '.prevArticle',
  },
}

export const ArticlesBlockSection = ({ assets }: ArticlesBlockSectionProps) => {
  return (
    <Styles.Container>
      <Styles.Title>{content.title}</Styles.Title>
      <Styles.Blurb>{content.blurb}</Styles.Blurb>
      <SwiperCarousel
        carouselName='ArticlesBlock'
        centeredSlides
        showArrows={false}
        showHeader={false}
        settings={carouselSettings}
      >
        {assets?.map((asset) => (
          <ArticleCard key={asset.id} asset={asset} />
        ))}
      </SwiperCarousel>
      <Styles.Controls>
        <div className='prevArticle'>
          <Icon.PurpleChevron />
        </div>
        <div className='nextArticle'>
          <Styles.Next />
        </div>
      </Styles.Controls>
    </Styles.Container>
  )
}

const Styles = {
  Container: styled.div`
    padding: 0 110px;

    ${breakpoint.mediumAndDown} {
      padding: 0 20px;
    }
  `,
  Title: styled.div`
    color: var(--neutrals800);
    text-align: center;
    font-size: 36px;
    font-style: normal;
    font-weight: 500;
    line-height: 44px;
    letter-spacing: 0.3px;

    ${breakpoint.mediumAndDown} {
      font-size: 20px;
      line-height: 24px;
    }
  `,
  Blurb: styled.div`
    color: var(--neutrals800);
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: 24px;
    letter-spacing: 0.3px;
    padding: 22px 50px 40px 50px;

    ${breakpoint.mediumAndDown} {
      padding: 22px 16px 32px 16px;
      font-size: 16px;
      line-height: 24px;
    }
  `,
  Controls: styled.div`
    display: none;
    justify-content: center;
    gap: 40px;

    ${breakpoint.mediumAndDown} {
      display: flex;
    }
  `,
  Next: styled(Icon.PurpleChevron)`
    transform: rotate(180deg);
  `,
}
