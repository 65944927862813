import { breakpoint, mixin } from 'src/styles'
import { ClassVarietySection } from './ClassVarietySection'
import { HeroSectionB } from './Hero'
import { InViewContainer } from 'src/components/InViewContainer'
import { Pricing } from 'src/components/Pricing'
import { SocialSection } from './SocialTout'
import { QuizPreviewCTA } from './QuizPreviewCTA'
import { useResponsive } from 'src/hooks/useResponsive'
import { withMarketingTracker } from './withMarketingTracker'
import { useAnonymousTreatment } from 'src/providers/Split/hooks/useAnonymousTreatment'
import { featureFlags } from 'src/providers/Split/utils'
import { ResearchStudySection } from './ResearchStudySection'
import { ArticlesBlockSection } from './ArticlesBlock'
import { PressTestimonialsSection } from './PressTestimonials'

const MarketingClassTypesSection = dynamic(() =>
  import('./MarketingClassTypesSection/MarketingClassTypesSection').then(
    (mod) => mod.MarketingClassTypesSection
  )
)
const InstructorsSection = dynamic(() =>
  import('./Instructors').then((mod) => mod.InstructorsSection)
)
const WorkAndPlayOrHealthierHappierSection = dynamic(() =>
  import('./WorkAndPlayOrHealthierHappier').then((mod) => mod.WorkAndPlayOrHealthierHappierSection)
)
const HappyHealthyOrPtSection = dynamic(() =>
  import('./HappyHealthyOrPtSection').then((mod) => mod.HappyHealthyOrPtSection)
)
const TestimonialsSection = dynamic(() =>
  import('./Testimonials').then((mod) => mod.TestimonialsSection)
)

const PricingSection = withMarketingTracker('pricingModule', Pricing)

const Landing = ({
  assets,
  marketing2023RedesignAssets,
  instructors,
  plans,
  selfieReel,
  articlesBlock,
  pressTestimonials,
}) => {
  const { isLargeAndUp, isXlarge } = useResponsive()
  const { treatment: quizBeforePaywallTreatment } = useAnonymousTreatment('Quiz_Before_Paywall')
  const { treatment: marketingPageCopyTreatment } = useAnonymousTreatment(
    featureFlags.marketingPageCopy
  )
  const { treatment: landingPageRedesign2023Treatment } = useAnonymousTreatment(
    featureFlags.landingPageRedesign2023
  )

  const sections = [
    {
      name: 'class-variety',
      component: <ClassVarietySection image={assets?.['three-devices-latest']} />,
    },
    {
      name: 'entertrainment',
      component: (
        <MarketingClassTypesSection marketing2023RedesignAssets={marketing2023RedesignAssets} />
      ),
    },
    {
      name: 'programs',
      component: (
        <WorkAndPlayOrHealthierHappierSection
          marketing2023RedesignAssets={marketing2023RedesignAssets}
          isLargeAndUp={isLargeAndUp}
        />
      ),
    },
    {
      name: 'happy-healthy',
      component: (
        <HappyHealthyOrPtSection
          selfieCarousel={selfieReel}
          marketing2023RedesignAssets={marketing2023RedesignAssets}
          isLargeAndUp={isLargeAndUp}
        />
      ),
    },

    ...(landingPageRedesign2023Treatment === 'variant_a_lp'
      ? [
          {
            name: 'articles-block',
            component: <ArticlesBlockSection assets={articlesBlock?.directusFiles} />,
          },
        ]
      : [
          {
            name: 'social',
            component: (
              <SocialSection
                image={assets?.['schedule-with-friends']}
                isLargeAndUp={isLargeAndUp}
              />
            ),
          },
        ]),

    ...(landingPageRedesign2023Treatment === 'variant_a_lp'
      ? [
          {
            name: 'research',
            component: (
              <ResearchStudySection
                image={marketing2023RedesignAssets?.['marketing-redesign-b6-desktop']}
              />
            ),
          },
        ]
      : [
          {
            name: 'instructors',
            component: (
              <InstructorsSection
                assets={assets}
                instructors={instructors}
                isLargeAndUp={isLargeAndUp}
                isXlarge={isXlarge}
              />
            ),
          },
        ]),

    { name: 'pricing', component: <PricingSection plans={plans} isLargeAndUp={isLargeAndUp} /> },

    ...(landingPageRedesign2023Treatment === 'variant_a_lp'
      ? [
          {
            name: 'press-testimonials',
            component: <PressTestimonialsSection assets={pressTestimonials?.directusFiles} />,
          },
        ]
      : [{ name: 'testimonials', component: <TestimonialsSection assets={assets} /> }]),
  ]

  React.useEffect(() => {
    obe.analytics.track(obe.events.experiment_launched, {
      splitExperiment: featureFlags.marketingPageCopy,
      splitVariant: marketingPageCopyTreatment,
    })

    obe.analytics.track(obe.events.experiment_launched, {
      splitExperiment: 'Quiz Before Paywall',
      splitVariant: quizBeforePaywallTreatment,
    })

    obe.analytics.track(obe.events.experiment_launched, {
      splitExperiment: 'Landing_Page_2023_Q3',
      splitVariant: landingPageRedesign2023Treatment,
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <React.Fragment>
      <HeroSectionB />
      {quizBeforePaywallTreatment === 'variant_a_qbp' && <QuizPreviewCTA linkTo='/obe-quiz' />}
      <Styles.Tout>{sections[0].component}</Styles.Tout>
      <InViewContainer>
        {sections.map((section, index) => {
          if (index === 0) {
            return null
          }
          return index % 2 !== 0 && landingPageRedesign2023Treatment !== 'variant_a_lp' ? (
            <Styles.Bleed key={section.name}>
              <Styles.Tout type={section.name}>{section.component}</Styles.Tout>
            </Styles.Bleed>
          ) : (
            <Styles.Tout type={section.name} key={section.name}>
              {section.component}
            </Styles.Tout>
          )
        })}
      </InViewContainer>
    </React.Fragment>
  )
}

const Styles = {
  Bleed: styled.div`
    background-color: var(--lightGrey);
    position: relative;
  `,
  Tout: styled.div`
    ${mixin.spaceChildrenVertical('40px')}
    padding: 80px 0;

    ${breakpoint.mediumAndDown} {
      padding: 40px 0;
    }

    ${({ type }) => {
      if (type === 'entertrainment') {
        return 'padding: 0px; background-color: white;'
      }
    }}

    ${({ type }) => {
      if (type === 'programs') {
        return 'padding: 30px 0;'
      }
    }}

    ${({ type }) => {
      if (type === 'happy-healthy') {
        return 'background-color: white; padding: 30px 100px'
      }
    }}

    ${({ type }) => {
      if (type === 'press-testimonials') {
        return 'padding: 0;'
      }
    }}

    ${({ type }) => {
      if (type === 'research' || type === 'articles-block') {
        return 'padding: 24px 0 48px 0;'
      }
    }}
  `,
}

export default Landing
