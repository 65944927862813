import React from 'react'
import { breakpoint, breakPointMins } from 'src/styles'
import { cloudflareLoader, widths } from 'src/utils/image'
import { DirectusFile } from 'src/types/DirectusFile'
import { SwiperCarousel } from 'src/components/Carousel/Swiper'

interface PressTestimonialsSectionProps {
  assets: DirectusFile[] | undefined
}

const carouselSettings = {
  autoplay: {
    delay: 3000,
    disableOnInteraction: false,
  },
  loop: true,
  slidesPerView: 3,
  spaceBetween: 24,
  breakpoints: {
    [breakPointMins.small]: {
      slidesPerView: 3,
      spaceBetween: 20,
    },
    [breakPointMins.medium]: {
      slidesPerView: 4,
      spaceBetween: 40,
    },
    [breakPointMins.xlarge]: {
      slidesPerView: 6,
      spaceBetween: 50,
      autoplay: false,
    },
  },
}

export const PressTestimonialsSection = ({ assets }: PressTestimonialsSectionProps) => {
  return (
    <Styles.OuterContainer>
      <Styles.Line />
      <Styles.Container>
        <SwiperCarousel
          carouselName='PressTestimonials'
          centeredSlides
          showArrows={false}
          showHeader={false}
          settings={carouselSettings}
        >
          {assets?.map(({ id, title, url }) => (
            <Styles.Slide key={id}>
              <Styles.Logo alt={title} src={cloudflareLoader({ src: url, width: widths.card })} />
            </Styles.Slide>
          ))}
          {assets?.map(({ id, title, url }) => (
            <Styles.Slide key={`${id}-2`}>
              <Styles.Logo
                alt={`${title}-2`}
                src={cloudflareLoader({ src: url, width: widths.card })}
              />
            </Styles.Slide>
          ))}
        </SwiperCarousel>
      </Styles.Container>
      <Styles.Line />
    </Styles.OuterContainer>
  )
}

const Styles = {
  OuterContainer: styled.div``,
  Container: styled.div`
    padding: 64px 64px 32px 64px;

    ${breakpoint.mediumAndDown} {
      padding: 32px 8px 0 8px;
    }
  `,
  Line: styled.div`
    height: 5px;
    background: linear-gradient(90deg, #ffb59c 0%, #f8ceff 45.54%, #d5d2ff 100%);
  `,
  Slide: styled.div`
    height: 150px;

    ${breakpoint.mediumAndDown} {
      height: 100px;
    }
  `,
  Logo: styled.img`
    height: 150px;
    width: 100%;
    object-fit: contain;

    ${breakpoint.mediumAndDown} {
      height: 100px;
    }
  `,
}
