import { breakpoint } from 'src/styles'
import { Icon } from 'src/components/Icon'
import { withMarketingTracker } from './withMarketingTracker'

const ClassVarietyView = () => {
  const copy = {
    title: 'Your routine, made easy',
    subTitle:
      'Discover your custom plan.  obé guides you through the what, when, and how of working out, so you have one less thing to stress about.',
    tiles: [
      {
        title: 'Feel progress',
        image: <Icon.ChartData />,
        description: 'Progressive classes combine work with play for real change',
      },
      {
        title: 'Save time',
        image: <Icon.ClockV2 />,
        description: 'Our data-informed algorithm plans 2 weeks of classes for you',
      },
      {
        title: 'Cut guesswork',
        image: <Icon.NoQuestion />,
        description: 'Your plan is designed to deliver the results you want',
      },
      {
        title: 'Stay flexible',
        image: <Icon.Lightening />,
        description: 'Easily edit your schedule or hit vacation mode',
      },
    ],
  }

  return (
    <Styles.Container>
      <Styles.LeftBlock>
        <Styles.LeftBlockVideo autoPlay loop muted playsInline>
          <source type='video/webm' src='marketing-redesign-2023-b1.webm' />
          <source type='video/mp4' src='marketing-redesign-2023-b1.mp4' />
        </Styles.LeftBlockVideo>
      </Styles.LeftBlock>
      <Styles.RightBlock>
        <Styles.TopHeadlineContainer>
          <Styles.Title>{copy.title}</Styles.Title>
          <Styles.Subtitle>{copy.subTitle}</Styles.Subtitle>
        </Styles.TopHeadlineContainer>
        <Styles.TilesContainer>
          {copy.tiles.map((tile) => (
            <Styles.TileContainer key={tile.title}>
              {tile.image}
              <Styles.TileTitle>{tile.title}</Styles.TileTitle>
              <Styles.TileDescription>{tile.description}</Styles.TileDescription>
            </Styles.TileContainer>
          ))}
        </Styles.TilesContainer>
      </Styles.RightBlock>
    </Styles.Container>
  )
}

const Styles = {
  Container: styled.div`
    display: flex;
    margin-left: 10px;
    margin-right: 10px;

    ${breakpoint.mediumAndDown} {
      flex-direction: column;
    }
  `,
  LeftBlock: styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 0.5;
    align-items: center;
    justify-content: center;
    width: 50%;
  `,
  LeftBlockVideo: styled.video`
    width: 600px;

    ${breakpoint.mediumAndDown} {
      margin-left: 110%;
      max-width: 300px;
      margin-bottom: 32px;
    }
  `,
  RightBlock: styled.div`
    flex: 0.5;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  `,
  TopHeadlineContainer: styled.div`
    display: flex;
    flex-direction: column;
    text-align: center;
    margin-bottom: 32px;
    width: 595px;

    ${breakpoint.mediumAndDown} {
      width: 95%;
      margin-left: 10px;
    }
  `,
  Title: styled.p`
    color: var(--neutrals800);
    font-size: 32px;
    font-weight: 500;
    line-height: 44px;
    letter-spacing: 0.3px;

    ${breakpoint.mediumAndDown} {
      font-size: 23px;
    }
  `,
  Subtitle: styled.p`
    color: var(--neutrals800);
    font-size: 16px;
    font-weight: 300;
    line-height: 24px;
    letter-spacing: 0.3px;

    ${breakpoint.mediumAndDown} {
      margin: 0 20px;
      max-width: 400px;
      align-self: center;
    }
  `,
  TilesContainer: styled.div`
    display: inline-flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 595px;
    gap: 52px;
    justify-content: space-between;

    ${breakpoint.mediumAndDown} {
      flex-direction: row;
      align-items: center;
      flex-wrap: wrap;
      width: 100%;
      max-width: 300px;
      gap: 20px;
    }
  `,
  TileContainer: styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 270px;
    text-align: center;

    ${breakpoint.mediumAndDown} {
      width: 45%;
    }
  `,
  TileTitle: styled.p`
    font-family: centra;
    color: var(--neutrals-500);
    font-size: 26px;
    font-weight: 500;
    line-height: 32px;
    letter-spacing: 0.3px;
    margin-top: 16px;
  `,
  TileDescription: styled.p`
    font-family: centra;
    color: #000;
    font-size: 16px;
    font-weight: 300;
    line-height: 24px;
    letter-spacing: 0.3px;

    ${breakpoint.mediumAndDown} {
      display: none;
    }
  `,
}

export const ClassVarietySection = withMarketingTracker('varietyModuleTest', ClassVarietyView)
