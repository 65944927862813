import React from 'react'
import { cloudflareLoader, widths } from 'src/utils/image'
import { mixin } from 'src/styles'
import { DirectusFile } from 'src/types/DirectusFile'

interface ArticleCardProps {
  asset: DirectusFile
  className?: string
}

export const ArticleCard = ({ asset, className }: ArticleCardProps) => {
  return (
    <Styles.Link href={asset.videoUrl} target='_blank' rel='noopener noreferrer'>
      <Styles.Container className={className}>
        <Styles.Image
          alt={asset.title}
          src={cloudflareLoader({ src: asset.url, width: widths.mobile })}
        />
        <Styles.Description dangerouslySetInnerHTML={{ __html: asset.description }} />
      </Styles.Container>
    </Styles.Link>
  )
}

const Styles = {
  Link: styled.a`
    width: 100%;
    text-decoration: none;
    padding: 10px;
    display: block;
    background: transparent;

    :hover,
    :focus {
      text-decoration: none;
    }
  `,
  Container: styled.div`
    ${mixin.cardBoxShadow}
    display: flex;
    flex-direction: column;
    border-radius: 10px 10px 10px 10px;
    background: transparent;
  `,
  Image: styled.img`
    display: block;
    width: 100%;
    height: 210px;
    object-fit: cover;
    border-radius: 10px 10px 0px 0px;
  `,
  Description: styled.div`
    font-size: 16px;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: 0.3px;
    padding: 24px;

    & > p {
      margin-bottom: 0;
    }
  `,
}
