import { breakpoint, button } from 'src/styles'
import { Link } from 'src/components/Link'
import { setReturnUrl } from 'src/utils/setReturnUrl'
import { useUiState } from 'src/hooks/useStores'
import { useAnonymousTreatment } from 'src/providers/Split/hooks/useAnonymousTreatment'
import { featureFlags } from 'src/providers/Split/utils'

type GetStartedButtonProps = {
  className?: string
}

export const GetStartedButton: React.FC<GetStartedButtonProps> = ({ className = undefined }) => {
  const { uiStore } = useUiState()
  const { treatment: quizBeforePaywallTreatment } = useAnonymousTreatment('Quiz_Before_Paywall')
  const { treatment: marketingPageCopyTreatment } = useAnonymousTreatment(
    featureFlags.marketingPageCopy
  )

  const sendToQuizFirst = quizBeforePaywallTreatment === 'variant_a_qbp'

  const onClick = () => {
    if (quizBeforePaywallTreatment === 'variant_a_qbp') {
      return setReturnUrl(/^\/videos\//)
    }
    obe.analytics.track('marketingModulesViewedOnSFT', uiStore?.marketingModulesViewed)
    setReturnUrl(/^\/videos\//)
  }

  return (
    <Styles.Button
      onClick={onClick}
      to={sendToQuizFirst ? '/obe-quiz' : `/users/sign_up${window.location.search}`}
      tracking={{
        location: 'hero',
        splitVariant: sendToQuizFirst ? quizBeforePaywallTreatment : marketingPageCopyTreatment,
      }}
      className={className}
    >
      Get started
    </Styles.Button>
  )
}

const Styles = {
  Button: styled(Link)`
    ${button.newPrimary}
    width: 100%;

    ${breakpoint.mediumAndUp} {
      width: fit-content;
    }
  `,
}
