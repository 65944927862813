import { isFirefox, isMobile, isIOS } from 'react-device-detect'
import { Play } from 'src/components/Display/Play'
import { breakpoint, mixin } from 'src/styles'
import { Flex } from 'src/components/Display/Flex'
import { useAnonymousTreatment } from 'src/providers/Split/hooks/useAnonymousTreatment'
import { withMarketingTracker } from '../withMarketingTracker'
import Image from 'next/image'
import desktopImage from 'public/marketing-hero-b.png'
import { GetStartedButton } from './GetStartedButton'
import content from './content'
import { featureFlags } from 'src/providers/Split/utils'

const Hero = React.memo(() => {
  const heroVideoRef = React.useRef<HTMLVideoElement>(null)

  const [canPlay, setCanPlay] = React.useState(false)
  const [playButtonVisible, setPlayButtonVisible] = React.useState(false)

  const { treatment: marketingPageCopyTreatment } = useAnonymousTreatment(
    featureFlags.marketingPageCopy
  )

  const playVideo = React.useCallback(async () => {
    try {
      if (heroVideoRef.current && canPlay) {
        await heroVideoRef.current.play()
        setPlayButtonVisible(false)
      }
    } catch (err) {
      setPlayButtonVisible(true)
    }
  }, [canPlay])

  return (
    <Styles.Container>
      {playButtonVisible && (
        <Styles.PlayButton role='button' onClick={playVideo}>
          <Styles.Play />
        </Styles.PlayButton>
      )}
      <Styles.Video>
        <video
          autoPlay
          loop
          muted
          onCanPlay={() => setCanPlay(true)}
          playsInline
          ref={heroVideoRef}
        >
          <source src='marketing-gradient-animation.mp4' type='video/mp4' />
        </video>
      </Styles.Video>
      <Styles.Content>
        <Styles.Tout width='50%' mediumAndDown={{ width: '100%' }}>
          <Styles.Title>
            {content[marketingPageCopyTreatment]?.title ?? content.control.title}
          </Styles.Title>
          <Styles.Subtitle>
            {content[marketingPageCopyTreatment]?.blurb ?? content.control.blurb}
          </Styles.Subtitle>
          <Styles.Button />
        </Styles.Tout>
        <Styles.ImageWrapper width='48%' mediumAndDown={{ width: '100%' }}>
          <Image
            src={desktopImage}
            alt='obé Fitness'
            layout='fill'
            objectFit='contain'
            objectPosition='bottom'
            priority
            unoptimized
          />
        </Styles.ImageWrapper>
      </Styles.Content>
    </Styles.Container>
  )
})

const Styles = {
  Container: styled.div`
    background: white;
    height: calc(100vh - var(--site-header-height));
    position: relative;

    ${breakpoint.largeAndUp} {
      height: calc(100vh - var(--site-header-height) + 100px);
    }

    ${breakpoint.smallAndUp} {
      min-height: 710px;
    }

    ${breakpoint.xsmallAndUp} {
      height: 610px;
    }
  `,
  Content: styled.div`
    display: flex;
    height: 100%;

    ${breakpoint.mediumAndDown} {
      flex-direction: column-reverse;
      align-items: center;
      justify-content: flex-end;
    }
  `,
  Tout: styled(Flex.Cell)`
    display: flex;
    flex-direction: column;
    align-items: start;
    position: relative;
    max-width: 640px;
    margin: auto;
    padding: 0px 20px;

    ${breakpoint.mediumAndDown} {
      flex: 0 0 20%;
      width: auto;
    }
  `,
  Title: styled.h1`
    font-weight: 400;
    font-size: 54px;
    line-height: 64px;
    color: var(--black);
    margin-bottom: 22px;

    ${breakpoint.xlargeAndDown} {
      font-size: 44px;
      line-height: 54px;
    }

    ${breakpoint.largeAndDown} {
      font-size: 38px;
      line-height: 48px;
    }

    ${breakpoint.mediumAndDown} {
      font-size: 32px;
      line-height: 42px;
      margin: 16px 0px;
    }
  `,
  Subtitle: styled.h2`
    font-weight: 300;
    font-size: 24px;
    line-height: 32px;
    color: var(--black);
    margin-bottom: 22px;
    max-width: 550px;

    ${breakpoint.xlargeAndDown} {
      font-size: 22px;
      line-height: 30px;
    }

    ${breakpoint.mediumAndDown} {
      font-size: 16px;
      line-height: 21px;
      margin-bottom: 16px;
    }
  `,
  Button: styled(GetStartedButton)`
    margin-bottom: 30px;

    ${breakpoint.mediumAndUp} {
      width: 340px;
    }

    ${breakpoint.mediumAndDown} {
      width: 100%;
    }
  `,
  ImageWrapper: styled(Flex.Cell)`
    position: relative;

    & > span {
      ${breakpoint.largeAndUp} {
        margin-top: 70px !important;
      }
    }

    img {
      padding: 0px 20px !important;

      ${breakpoint.mediumAndDown} {
        mask-image: -webkit-gradient(
          linear,
          left 90%,
          left bottom,
          from(rgba(0, 0, 0, 1)),
          to(rgba(0, 0, 0, 0))
        );
        object-position: 50% 30px !important;
      }
    }

    ${breakpoint.mediumAndDown} {
      flex: 0 1 70%;
    }
  `,
  Video: styled.div`
    ${mixin.cover}
    overflow: hidden;

    video {
      height: calc(100%);
      object-fit: cover;
      width: 100%;
    }
  `,
  PlayButton: styled.div`
    position: absolute;
    margin-top: ${isMobile && isFirefox && !isIOS ? -42 : -64}px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 64px;
    height: 64px;
    z-index: 1;
    background: rgba(0, 0, 0, 0.3);
    border-radius: 64px;

    ${breakpoint.smallAndUp} {
      margin-top: 0;
    }
  `,
  Play: styled(Play)`
    height: 64px;
    width: 64px;
    path {
      fill: var(--neutrals100);
      stroke: var(--neutrals100);
    }
    circle {
      stroke: var(--neutrals100);
    }
  `,
}

export const HeroSectionB = withMarketingTracker('heroModule', Hero)
