import { breakpoint, button, mixin } from 'src/styles'
import { Flex } from 'src/components/Display/Flex'
import { Image } from 'src/components/Display/Image'
import { LandingStyles } from '../styles'
import { Link } from 'src/components/Link'
import { useUiState } from 'src/hooks/useStores'
import { useAnonymousTreatment } from 'src/providers/Split/hooks/useAnonymousTreatment'
import { withMarketingTracker } from '../withMarketingTracker'
import content from './content'
import { featureFlags } from 'src/providers/Split/utils'

const SocialTout = ({ image, isLargeAndUp }) => {
  const { treatment: marketingPageCopyTreatment } = useAnonymousTreatment(
    featureFlags.marketingPageCopy
  )

  return (
    <Styles.Container>
      <Flex.Cell largeAndUp={{ width: '520px' }}>
        <Styles.Content>
          <LandingStyles.Title>
            {content[marketingPageCopyTreatment]?.title ?? content.control.title}
          </LandingStyles.Title>
          <LandingStyles.Blurb>
            {content[marketingPageCopyTreatment]?.blurb ?? content.control.blurb}
          </LandingStyles.Blurb>
          {isLargeAndUp && <CtaButton />}
        </Styles.Content>
      </Flex.Cell>
      <Flex.Cell largeAndUp={{ width: '50%' }}>
        <Image src={image} height={0.58} />
        {!isLargeAndUp && <CtaButton />}
      </Flex.Cell>
    </Styles.Container>
  )
}

const CtaButton = () => {
  const { uiStore } = useUiState()
  return (
    <Styles.Button
      onClick={() => {
        obe.analytics.track('marketingModulesViewedOnSFT', uiStore?.marketingModulesViewed)
      }}
      synthetic={false}
      to='/users/sign_up'
    >
      Get moving
    </Styles.Button>
  )
}

const Styles = {
  Container: styled.div`
    ${mixin.flexCenterVertical}
    ${mixin.flexSpaceAround}
    padding: 0 110px;
    column-gap: 100px;

    ${breakpoint.mediumAndDown} {
      align-items: normal;
      flex-direction: column;
      row-gap: 40px;
      padding: 0 20px;
    }
  `,
  Content: styled.div`
    ${mixin.spaceChildrenVertical('24px')}

    ${breakpoint.mediumAndDown} {
      ${mixin.spaceChildrenVertical('16px')}
    }
  `,
  Button: styled(Link)`
    ${button.newPrimary}
    margin-top: 64px;
    width: fit-content;

    ${breakpoint.mediumAndDown} {
      max-width: none;
      width: 100%;
      margin-top: 40px;
    }
  `,
}

export const SocialSection = withMarketingTracker('workoutPartyModule', SocialTout)
